import { TrainJob } from "./trainJob";

export default function fetchJobs(
  jobs: TrainJob[] | null, // will append to existing jobs if they are passed
  setJobs: (jobs: TrainJob[]) => void,
  token: string,
  setOnBottom: (onBottom: boolean) => void,
  page: number,
) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  var limit = 20;
  var body = JSON.stringify({
    offset: 0 + page * limit,
    limit: limit,
    page: page,
  });

  fetch((process.env.REACT_APP_API_URL || "") + "/v1/train/jobs", {
    method: "POST",
    headers: myHeaders,
    body: body,
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .then((json) => {
      json.map((job: TrainJob) => {
        if (!job.model_name) {
          job.model_name = "";
        }
        if (!job.custom_model_name) {
          job.custom_model_name = "";
        }
        if (!job.resume_count) {
          job.resume_count = 0;
        }
        if (!job.resume_limit) {
          job.resume_limit = 0;
        }
        if (!job.dataset_id) {
          job.dataset_id = "";
        }
        const d = new Date(job.start_time + "Z");
        job.start_time = d.toLocaleString([], {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
        return job;
      });
      setOnBottom(false);
      if (jobs === null) {
        setJobs(json);
        return;
      }
      if (json.length === 0) {
        return;
      }
      setJobs([...new Set(jobs.concat(json))]);
    })
    .catch((error) => {
      console.error("Error fetching jobs: ", error);
      setJobs([]);
    });
}
