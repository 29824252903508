import * as React from "react";
import { Spinner } from "../system/atoms/Spinner";
import fetchTrainArguments from "../../utils/fetchTrainArguments";

interface TrainArgumentsProps {
  token: string;
  job_id: string;
}

export default function TrainArguments({ token, job_id }: TrainArgumentsProps) {
  const [trainArguments, setTrainArguments] = React.useState<string>("");
  React.useEffect(() => {
    fetchTrainArguments(job_id, setTrainArguments, token);
  }, []);

  // fetchTrainingLog has not finished yet so don't render jobs (show loading)
  if (trainArguments === null) {
    return (
      <div className="flex h-screen">
        <div className="m-auto">
          <Spinner enabled={true}></Spinner>
        </div>
      </div>
    );
  }
  return (
    <div className="h-full font-mono text-xs">
      <pre aria-label="Train Arguments" className="overflow-y-auto w-full">
        {trainArguments}
      </pre>
    </div>
  );
}
