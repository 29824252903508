import * as Sentry from "@sentry/react";

export async function fetchStreamingCustom(
  finalQuestion: string,
  baseModel: string | null,
  setModelResponse: (response: string, responseType: string) => void,
  token: string,
  outputLength: number,
  giveUpAfter: number,
  showWarningTime: number | undefined,
  setIsCreditModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const body = {
    id: "website",
    model_name: baseModel,
    prompt: finalQuestion,
    max_new_tokens: outputLength,
  };

  // Define a polling interval in milliseconds
  const pollingInterval = 60; // polling interval in milliseconds
  const maxDurationInSeconds = 500; // maximum duration in seconds
  // Use a flag to control the polling loop
  let id = "";
  let stopPolling = false;
  let hasReceivedFirstToken = false;
  let elapsedTime = 0;
  const startTime = Date.now();

  Sentry.addBreadcrumb({
    category: "request_body",
    message:
      "model_name: " +
      body.model_name +
      "\nprompt: " +
      body.prompt +
      "\noutput_length: " +
      body.max_new_tokens,
    level: "info",
  });

  ({ id, stopPolling } = await submitStreamingCompletions(
    body,
    myHeaders,
    setModelResponse,
    setIsCreditModalOpen,
  ));

  while (!stopPolling) {
    if (elapsedTime > giveUpAfter * 1000 && !hasReceivedFirstToken) {
      setModelResponse(
        "There is a large amount of traffic currently, waiting for a response.",
        "warning",
      );
      Sentry.captureException(
        new Error("Oops, there is too much traffic. Please try again."),
      );
      break;
    } else if (
      showWarningTime != null &&
      elapsedTime > showWarningTime * 1000 &&
      !hasReceivedFirstToken
    ) {
      setModelResponse(
        "There is a large amount of traffic currently, waiting for a response...",
        "warning",
      );
    } else if (elapsedTime > maxDurationInSeconds * 1000) {
      break;
    }
    if (elapsedTime > maxDurationInSeconds * 1000) {
      return;
    }
    ({ stopPolling, hasReceivedFirstToken } = await pollStreamingResult(
      id,
      myHeaders,
      setModelResponse,
      hasReceivedFirstToken,
      showWarningTime == null
        ? giveUpAfter
        : Math.min(giveUpAfter, showWarningTime),
      setIsCreditModalOpen,
    ));
    // Wait for the next polling interval
    await new Promise((resolve) => setTimeout(resolve, pollingInterval));

    elapsedTime = Date.now() - startTime;
  }

  Sentry.captureEvent({
    message: "Successful completions API call",
    level: "info",
  });
}

async function submitStreamingCompletions(
  body: any,
  myHeaders: any,
  setModelResponse: (response: string, responseType: string) => void,
  setIsCreditModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const controller = new AbortController();
  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || "") + "/v3/streaming_completions",
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: myHeaders,
        signal: controller.signal, // Pass the abort signal to the fetch options
      },
    );
    const responseJson = await response.json();

    if (!response.ok) {
      handleError(response, setIsCreditModalOpen, setModelResponse);
    }
    return { id: responseJson.id, stopPolling: false };
  } catch (error) {
    setModelResponse("Oops, something went wrong. Please try again.", "error");
    Sentry.captureException(error);
    return { id: "", stopPolling: true };
  }
}

export async function pollStreamingResult(
  id: any,
  myHeaders: any,
  setModelResponse: (response: string, responseType: string) => void,
  hasReceivedFirstToken: boolean,
  request_timeout: number, // maximum duration for wait to get first token in seconds
  setIsCreditModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const controller = new AbortController();
  const timeoutId = setTimeout(
    () => controller.abort(),
    request_timeout * 1000,
  ); // 3000 milliseconds timeout

  try {
    const response = await fetch(
      (process.env.REACT_APP_API_URL || "") +
        `/v3/streaming_completions/${id}/result`,
      {
        method: "GET",
        headers: myHeaders,
        signal: controller.signal, // Pass the abort signal to the fetch options
      },
    );
    const responseJson = await response.json();
    console.log(responseJson);

    clearTimeout(timeoutId); // Clear the timeout if the request is successful
    if (!response.ok) {
      handleError(response, setIsCreditModalOpen, setModelResponse);
    }
    if (Object.keys(responseJson).length === 0) {
      return {
        stopPolling: false,
        hasReceivedFirstToken: false,
      };
    }
    // Initialize an empty response
    let partialResponse = "";
    let stopPolling = responseJson.finish_reason[0] != null;
    // Append the received chunk to the partial response
    partialResponse += responseJson.outputs[0].output;
    // Update the model response in the state
    if (partialResponse === "") {
      // Don't set the ModelResponse else it would remove the loading dots
      return {
        stopPolling: false,
        hasReceivedFirstToken,
      };
    }
    setModelResponse(partialResponse, "model");

    return {
      stopPolling,
      hasReceivedFirstToken: true,
    };
  } catch (error) {
    // Handle abort error
    if (error instanceof Error && error.name === "AbortError") {
      console.log("abort error");
      return {
        stopPolling: false,
        hasReceivedFirstToken,
      };
    }
    setModelResponse("Oops, something went wrong. Please try again.", "error");
    Sentry.captureException(error);
    console.log(error);
    return {
      stopPolling: true,
      hasReceivedFirstToken,
    };
  }
}

function handleError(
  response: Response,
  setIsCreditModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setModelResponse: (response: string, responseType: string) => void,
) {
  let error_message = "";

  if (response.status === 513) {
    error_message =
      "The requested model is currently being downloaded to our servers. This process typically takes a few minutes. Please try again shortly. If the issue persists after 15 minutes, contact our support team.";
  } else if (response.status === 503) {
    error_message =
      "Our servers are temporarily unavailable. We're working to resolve this as quickly as possible. Please try again in a few minutes.";
  } else if (response.status === 561) {
    error_message =
      "You don't have access to this model. This may be due to account restrictions. Please try another model.";
  } else if (response.status === 402) {
    error_message =
      "Your account has insufficient credits. To continue using Lamini, please buy more credits.";
    setIsCreditModalOpen(true);
  } else {
    error_message =
      "An unexpected error occurred. We've logged the issue and our team is investigating. Please try your request again. If the problem persists, contact our support team with error ID.";
  }

  setModelResponse(error_message, "error");
  Sentry.captureException(new Error(error_message));
}
