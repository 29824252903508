import React from "react";

interface AlignedSidesProps {
  left?: React.ReactNode;
  right?: React.ReactNode;
}

export const AlignedSides = ({ left, right }: AlignedSidesProps) => {
  return (
    <div className="default flex">
      <div>{left && left}</div>
      <div className="grow min-w-[1rem]"></div>
      <div>{right && right}</div>
    </div>
  );
};
