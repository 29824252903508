import * as React from "react";
import { Title } from "../system/atoms/Title";
import { Spacer } from "../system/layouts/Spacer";
import { Container } from "../system/layouts/Container";
import { Centered } from "../system/layouts/Centered";
import { Padded } from "../system/layouts/Padded";
import LiveInferenceUsageChart from "../user/LiveInferenceUsageChart";

interface AdminPageProps {
  token: string;
}

export default function AdminPage(props: AdminPageProps) {
  return (
    <Centered>
      <Padded>
        <div className="mt-4">
          <Title value={"Live GPU usage"} level={2} />
          <Spacer />
          <Container>
            <LiveInferenceUsageChart token={props.token} />
          </Container>
        </div>
      </Padded>
    </Centered>
  );
}
