import * as React from "react";
import { Spacer } from "../system/layouts/Spacer";
import { Description, Field, Label, RadioGroup } from "@headlessui/react";
import { Button } from "../system/atoms/Button";
import demoFile from "../../assets/earnings-calls-demo.jsonl";

const MODELS: Model[] = [
  { name: "Llama 3.1", description: "meta-llama/Meta-Llama-3.1-8B-Instruct" },
  { name: "Mistral 3", description: "mistralai/Mistral-7B-Instruct-v0.3" },
];

interface Model {
  name: string;
  description: string;
}

interface ModelSelectorProps {
  selectedModel: Model;
  setSelectedModel: React.Dispatch<React.SetStateAction<Model>>;
}

function ModelSelector({
  selectedModel,
  setSelectedModel,
}: ModelSelectorProps) {
  return (
    <RadioGroup
      value={selectedModel}
      aria-label="Model name"
      className="flex space-x-4 overflow-x-auto p-2"
    >
      {MODELS.map((model) => (
        <Field
          key={model.description}
          className={`border-[#2b2b2b] rounded-2xl border-2 p-4 mb-3 hover:bg-[#2b2b2b] ${
            selectedModel.name === model.name && "border-[#FF6C71]"
          }`}
          onClick={() => setSelectedModel(model)}
        >
          <div>
            <Label>{model.name}</Label>
            <Description className="opacity-50">
              {model.description}
            </Description>
          </div>
        </Field>
      ))}
    </RadioGroup>
  );
}

const textToTemplates = (
  text: string,
  repeatData: number,
  uploadBasePath: string,
): any[] => {
  const lines = text.split("\n");
  let parts = [];

  for (let line of lines) {
    if (line) {
      for (let i = 0; i < repeatData; i++) {
        const lineObj = JSON.parse(line);
        let input = lineObj.question ? lineObj.question : lineObj.input;
        let output = lineObj.answer ? lineObj.answer : lineObj.output;
        let obj = {
          input: input,
          output: output,
        };
        if (uploadBasePath === "azure") {
          parts.push(JSON.stringify(obj) + "\n");
        } else {
          parts.push(obj);
        }
      }
    }
  }
  return parts;
};

async function submitTuningJob(
  model_name: string,
  env: string,
  token: string,
  onJobSubmit: () => void,
  setError: (error: ErrorMessage) => void,
) {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  let datasetBody = {
    id: "website",
    upload_base_path: "",
    args_name: "demo",
  };
  let response = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/dataset-info",
    {
      method: "POST",
      body: JSON.stringify(datasetBody),
      headers: myHeaders,
    },
  );
  let responseJson = await response.json();
  const repeatData = 1;
  const uploadBasePath = responseJson.hasOwnProperty("upload_base_path")
    ? responseJson["upload_base_path"]
    : "azure";

  const getTrainingData = async (): Promise<string> => {
    try {
      const response = await fetch(demoFile);
      return await response.text();
    } catch (err) {
      return "";
    }
  };

  const trainingParts = textToTemplates(
    await getTrainingData(),
    repeatData,
    uploadBasePath,
  );

  const shuffledParts = trainingParts.sort((a, b) => 0.5 - Math.random());

  // upload data to server
  myHeaders.append("X-Client", "frontend");
  let datasetId = "";
  let datasetLocation = "";

  if (uploadBasePath === "azure") {
    const { BlobServiceClient } = await import("@azure/storage-blob");
    const sas_token = responseJson["sas_token"];
    const blobSasUrl =
      "https://laministorage.blob.core.windows.net/?" + sas_token;
    const blobServiceClient = new BlobServiceClient(blobSasUrl);
    datasetId = responseJson.hasOwnProperty("dataset_id")
      ? responseJson["dataset_id"]
      : "";
    const blob = new Blob(shuffledParts, { type: "text/plain" });
    const containerName = "training-data";
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(
      "platform/" + datasetId,
    );
    const resp = await blockBlobClient.uploadBrowserData(blob);
  } else {
    let body = {
      id: "website",
      data: shuffledParts,
      upload_base_path: uploadBasePath,
    };

    response = await fetch(
      (process.env.REACT_APP_API_URL || "") + "/v1/local-data",
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: myHeaders,
      },
    );
    responseJson = await response.json();
    datasetId = responseJson["dataset_id"];
    datasetLocation = responseJson["dataset_location"];
  }

  const body = {
    id: "website",
    model_name: model_name,
    upload_file_path: datasetLocation,
    dataset_id: datasetId,
    finetune_args: {
      args_name: "demo",
      max_steps: 60,
      r_value: 32,
      learning_rate: 0.0003,
    },
    source: { id: "website", data_type: "sample" },
  };
  fetch((process.env.REACT_APP_API_URL || "") + "/v1/train", {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.ok) {
        onJobSubmit();
        return response.json();
      }
      throw response;
    })
    .catch((error) => {
      setError(error);
    });
}

interface TrainPageSubmitJobProps {
  token: string;
  onJobSubmit: () => void;
  env: string;
}

type ErrorMessage = string | null;

export default function TrainPageSubmitJob({
  token,
  onJobSubmit,
  env,
}: TrainPageSubmitJobProps) {
  const [selectedModel, setSelectedModel] = React.useState(MODELS[0]);
  const [error, setError] = React.useState<ErrorMessage>(null);

  return (
    <div className="default 2xl:max-w-screen-lg lg:max-w-screen-lg md:max-w-screen-md sm:max-w-[960px] m-auto flex flex-col items-center justify-center h-[500px]">
      <div className="text-3xl">Welcome to Lamini 🦙</div>
      <Spacer />
      <div>
        Let's get started! Try tuning a model on a{" "}
        <a
          href={demoFile}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "underline", color: "#FF6C71" }}
        >
          sample dataset
        </a>
        .
      </div>
      <Spacer />
      <Spacer />
      <ModelSelector
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
      />
      <Spacer />
      <Spacer />
      <Spacer />
      <Button
        label="Submit Tuning Job"
        onClick={() =>
          submitTuningJob(
            selectedModel.description,
            env,
            token,
            onJobSubmit,
            setError,
          )
        }
      />
      <Spacer />
      {error && (
        <div className="text-[#FF6C71]">
          Sorry, there was an error submitting your job. Please try again!
        </div>
      )}
    </div>
  );
}
