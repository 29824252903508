export interface JobEvalResult {
  input: string;
  outputs: JobEvalResultOutput[];
}

export interface JobEvalResultOutput {
  model_name: string;
  output: string;
}

export default function fetchJobEval(
  job_id: string,
  setJobEval: (jobEval: Array<JobEvalResult>) => void,
  token: string,
  setJobEvalLoading: (jobEvalLoading: boolean) => void,
) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");
  if (job_id === "OOO") {
    return;
  }
  setJobEvalLoading(true);
  fetch(
    (process.env.REACT_APP_API_URL || "") +
      "/v1/train/jobs/" +
      job_id +
      "/eval",
    {
      method: "GET",
      headers: myHeaders,
    },
  )
    .then((response) => response.json())
    .then((json) => {
      // Pre MoME eval values
      if (json.eval_results.length === 0) {
        checkMoMEEvalResults(
          job_id,
          setJobEval,
          token,
          setJobEvalLoading,
          myHeaders,
        );
      } else {
        setJobEval(json.eval_results);
        setJobEvalLoading(false);
      }
    })
    .catch((error) => {
      checkMoMEEvalResults(
        job_id,
        setJobEval,
        token,
        setJobEvalLoading,
        myHeaders,
      );
    });
}

function checkMoMEEvalResults(
  job_id: string,
  setJobEval: (jobEval: Array<JobEvalResult>) => void,
  token: string,
  setJobEvalLoading: (jobEvalLoading: boolean) => void,
  myHeaders: Headers,
) {
  return fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/finetune_eval/jobs/" + job_id,
    {
      method: "GET",
      headers: myHeaders,
    },
  )
    .then((response) => response.json())
    .then((json) => {
      let inputs: Array<Array<string>> = [];
      let inputSet = new Set();
      for (let i = 0; i < json.length; i++) {
        const results = json[i].results;
        for (let j = 0; j < results.length; j++) {
          if (!inputSet.has(results[j].input)) {
            inputSet.add(results[j].input);
            inputs.push([results[j].input, results[j].reference_output]);
          }
        }
      }
      const evalResults: Array<JobEvalResult> = inputs.map((item) => {
        return {
          input: item[0],
          outputs: [{ model_name: "gold", output: item[1] }],
        };
      });

      for (let i = 0; i < json.length; i++) {
        const results = json[i].results;
        const model_name = json[i].model_name;
        for (let j = 0; j < results.length; j++) {
          const index = evalResults.findIndex(
            (value) => value.input == results[j].input,
          );
          const output = results[j].output;
          evalResults[index].outputs.push({
            model_name,
            output,
          });
        }
      }
      setJobEval(evalResults);
    })
    .finally(() => setJobEvalLoading(false));
}
