export default function fetchTrainArguments(
  job_id: string,
  setTrainArguments: (s: string) => void,
  token: string,
) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  fetch(
    (process.env.REACT_APP_API_URL || "") +
      "/v1/train/jobs/" +
      job_id +
      "/arguments",
    {
      method: "POST",
      headers: myHeaders,
    },
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .then((json) => {
      if (!json.arguments) {
        setTrainArguments("No training arguments found");
        return;
      }
      setTrainArguments(JSON.stringify(json.arguments, null, "\t"));
    })
    .catch((error) => {
      setTrainArguments(
        "Error fetching training arguments, see console for more details",
      );
    });
}
