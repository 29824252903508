import { TrainJob } from "./trainJob";

export default function fetchJob(
  job_id: string,
  setJob: (job: TrainJob) => void,
  setJobHistory: (history: []) => void,
  token: string,
) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");
  if (job_id === "OOO") {
    return;
  }

  fetch((process.env.REACT_APP_API_URL || "") + "/v1/train/jobs/" + job_id, {
    method: "GET",
    headers: myHeaders,
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .then((json) => {
      if (!json.model_name) {
        json.model_name = "";
      }
      if (!json.custom_model_name) {
        json.custom_model_name = "";
      }
      if (!json.resume_count) {
        json.resume_count = 0;
      }
      if (!json.resume_limit) {
        json.resume_limit = 0;
      }
      if (!json.dataset_id) {
        json.dataset_id = "";
      }

      const d = new Date(json.start_time + "Z");
      json.start_time = d.toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
      setJob(json);
      setJobHistory(JSON.parse(json.history) ?? []);
    })
    .catch((error) => {
      console.error("Error fetching job: ", error);
      setJob({
        job_id: job_id,
        status: "",
        model_name: "",
        start_time: "",
        resume_count: 0,
        resume_limit: 0,
        dataset_id: "",
      } as TrainJob);
    });
}
