import * as React from "react";
import { JobEvalResult } from "../../utils/fetchJobEval";
import EvalInputOutputItem, { EvalResultsData } from "./EvalInputOutputItem";
import { Token } from "../system/atoms/Token";

interface EvalResultsProps {
  jobEval: JobEvalResult[];
  modelId: string;
}

function isGoldModel(name: string) {
  return name == "gold";
}

function isBaseModel(name: string, modelId: string) {
  return name != modelId && !isGoldModel(name);
}

function isTrainedModel(name: string, modelId: string) {
  return name == modelId;
}

export default function EvalResults({ jobEval, modelId }: EvalResultsProps) {
  const [comparisonModel, setComparisonModel] = React.useState<string>("gold");

  const data: EvalResultsData[] = jobEval.map((evalResult) => {
    const base_model_result = evalResult.outputs.find((output) =>
      isBaseModel(output.model_name, modelId),
    );
    const trained_model_result = evalResult.outputs.find((output) =>
      isTrainedModel(output.model_name, modelId),
    );
    const gold_result = evalResult.outputs.find((output) =>
      isGoldModel(output.model_name),
    );

    return {
      question: evalResult.input,
      trained_model: trained_model_result?.output || "",
      base_model: base_model_result?.output || "",
      gold: gold_result?.output || "",
      base_model_name: base_model_result?.model_name || "",
      trained_model_name: trained_model_result?.model_name || "",
      gold_name: "Gold response",
    };
  });
  return (
    <>
      <div className="w-full pl-3.5 grid gap-x-6 grid-cols-2 bg-[#1f1f1f] ">
        <div>
          <Token label={"This model"} active={false}></Token>
        </div>
        <div>
          <div className="flex">
            <div className="mr-2">
              <Token
                label={"Gold Response"}
                onClick={() => setComparisonModel("gold")}
                active={comparisonModel === "gold"}
              ></Token>
            </div>
            <div>
              <Token
                label={data[0].base_model_name || "Base model"}
                onClick={() => setComparisonModel("base")}
                active={comparisonModel === "base"}
              ></Token>
            </div>
          </div>
        </div>
      </div>

      <ul aria-label="Eval Results">
        {data.map((d, i) => (
          <EvalInputOutputItem
            key={`eval-item-${i}`}
            data={d}
            i={i}
            comparisonModel={comparisonModel}
          />
        ))}
      </ul>
    </>
  );
}
