import { TrainJob } from "./trainJob";

export default function setCustomName(
  model_name: string,
  custom_model_name: string,
  token: string,
  activeJob: TrainJob,
  setActiveJob: (activeJob: TrainJob) => void,
) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  fetch(
    (process.env.REACT_APP_API_URL || "") +
      "/v1/model/" +
      encodeURIComponent(model_name) +
      "/custom_model_name/" +
      encodeURIComponent(custom_model_name),
    {
      method: "POST",
      headers: myHeaders,
    },
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .then((json) => {
      const newActiveJob = activeJob;
      newActiveJob.custom_model_name = json.custom_model_name;
      setActiveJob(newActiveJob);
    })
    .catch((error) => {
      console.error("Error setting custom name: ", error);
    });
}
