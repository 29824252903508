import * as React from "react";
import checkIcon from "../assets/check-icon.svg";
import copyIcon from "../assets/copy-icon.svg";

interface CopyToClipboardProps {
  /** * Text to copy to clipboard */
  text: string;
}

export const CopyToClipboard = ({ text }: CopyToClipboardProps) => {
  const [copy, setCopy] = React.useState<Record<string, boolean>>({});
  const [showCheck, setShowCheck] = React.useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopy((prevState) => ({
        ...prevState,
        [text]: true,
      }));
      setShowCheck(true);
      setTimeout(() => {
        setShowCheck(false);
      }, 10000); // Remove check icon after 10 seconds
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <div onClick={handleCopy}>
      {showCheck ? (
        <img
          className="w-[26px] min-w-[26px] h-full pl-2"
          src={checkIcon}
          alt="check-icon"
        />
      ) : (
        <img
          className="w-[26px] min-w-[26px] h-full pl-2"
          src={copyIcon}
          alt="copy-icon"
        />
      )}
    </div>
  );
};
