import * as React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export interface UsageInfo {
  usage: { date: string; credits_used: number }[];
}

export default function UsageChart(props: UsageInfo) {
  const data = React.useMemo(() => {
    return props.usage.map((elem) => {
      return { date: elem.date, value: elem.credits_used };
    });
  }, [props.usage]);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={data}
        margin={{ top: 20, right: 20, left: 5, bottom: 20 }}
      >
        <XAxis dataKey="date" />
        <YAxis
          label={{
            value: "Credits Used",
            angle: -90,
            position: "insideLeft",
          }}
        />
        <Tooltip />
        <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
        <Line type="monotone" dataKey="value" stroke="#8884d8" yAxisId={0} />
      </LineChart>
    </ResponsiveContainer>
  );
}
