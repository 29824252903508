import { ChatHistoryItem } from "../components/system/atoms/ChatHistory";

export function constructLlamaPrompt(
  message: string,
  chatHistory: ChatHistoryItem[],
  systemPrompt: string,
): string {
  let constructedMessage = `<|begin_of_text|>`;
  constructedMessage += llama3System(systemPrompt);
  for (var i = 0; i < chatHistory.length; i++) {
    const historyItem = chatHistory[i];
    if (historyItem.entity === "user") {
      // Append User message
      constructedMessage += llama3User(historyItem.message);
    } else {
      // Append Model message
      constructedMessage += llama3AssistantMessage(historyItem.message);
    }
  }
  constructedMessage += llama3User(message);
  constructedMessage += llama3AssistantPrompt();
  return constructedMessage;
}

function llama3System(system_prompt: string) {
  const message = `<|start_header_id|>system<|end_header_id|>\n\n${system_prompt}<|eot_id|>`;
  return message;
}

function llama3User(user_message: string) {
  const message = `<|start_header_id|>user<|end_header_id|>\n\n${user_message}<|eot_id|>`;
  return message;
}

function llama3AssistantMessage(assistant_message: string) {
  const message = `<|start_header_id|>assistant<|end_header_id|>\n\n${assistant_message}<|eot_id|>`;
  return message;
}

function llama3AssistantPrompt() {
  const message = `<|start_header_id|>assistant<|end_header_id|>\n\n`;
  return message;
}

export function constructMistralPrompt(
  message: string,
  chatHistory: ChatHistoryItem[],
  systemPrompt: string,
): string {
  let constructedMessage = `<s>`;
  for (var i = 0; i < chatHistory.length; i++) {
    const historyItem = chatHistory[i];
    if (historyItem.entity === "user") {
      constructedMessage += `[INST] `;
      // First message has the system prompt
      if (i === 0) {
        constructedMessage += `${systemPrompt} `;
      }
      constructedMessage += `${historyItem.message} [/INST]`;
    } else {
      constructedMessage += `${historyItem.message}</s> `;
    }
  }

  if (chatHistory.length === 0) {
    constructedMessage += `[INST] ${systemPrompt} ${message} [/INST]`;
  } else {
    constructedMessage += `[INST] ${message} [/INST]`;
  }
  return constructedMessage;
}

export function constructPhiPrompt(
  message: string,
  chatHistory: ChatHistoryItem[],
  systemPrompt: string,
): string {
  let constructedMessage = ``;
  if (systemPrompt.length != 0) {
    constructedMessage += `Instruct: ${systemPrompt}\n`;
  }
  for (var i = 0; i < chatHistory.length; i++) {
    const historyItem = chatHistory[i];
    if (historyItem.entity === "user") {
      constructedMessage += `User: ${historyItem.message}\n`;
    } else {
      constructedMessage += `Assisstant: ${historyItem.message}\n`;
    }
  }
  constructedMessage += `User: ${message} \nAssistant: `;
  return constructedMessage;
}
