import * as React from "react";
import { TrainJob } from "../../utils/trainJob";
import { statusChip } from "./statusChip";

interface TrainCardProps {
  job: TrainJob;
  onClick: () => void;
  selected: boolean;
}

export default function TrainCard({ job, onClick, selected }: TrainCardProps) {
  let style = selected ? "border-[#FF6C71]" : "border-gray-600";
  style += " rounded-2xl border p-4 mb-3 cursor-pointer hover:bg-[#1d1d1d]";
  return (
    <li key={job.job_id} onClick={onClick} className={style}>
      <div className="w-full">
        <div className="flex w-full justify-between">
          <p className="truncate w-[90px]">
            {job.model_name
              ? job.custom_model_name
                ? job.custom_model_name
                : job.model_name
              : "Unavailable"}
          </p>
          <p>{statusChip(job.status)}</p>
        </div>
        <div className="flex w-full justify-between">
          <p>{job.job_id}</p>
          <p>{job.start_time}</p>
        </div>
      </div>
    </li>
  );
}
