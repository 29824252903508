import React, { useState, useEffect, useRef } from "react";
import Markdown from "react-markdown";
import CodeBlock, { ChatHistoryItem } from "../system/atoms/ChatHistory";
import pinkLlama from "../../../assets/icon_lamini.png";

export interface ChatHistoryUserBubbleProps {
  chat: ChatHistoryItem;
}

export const ChatHistoryUserBubble = ({ chat }: ChatHistoryUserBubbleProps) => {
  return (
    <div className="flex w-full max-w-full flex-row-reverse">
      {chat.entity === "user" && (
        <div className="whitespace-pre-wrap break-words bg-[#FF6C71] border-[1px] border-[#393939] p-2 px-4 rounded-lg inline-block">
          <Markdown>{chat.message}</Markdown>
        </div>
      )}
      {chat.entity === "error" || chat.entity === "warning" ? (
        <div
          className={`text-[14px] ${
            chat.entity === "error" ? "text-[#FF0000]" : "text-[#FF5F15]"
          }`}
        >
          {chat.message}
        </div>
      ) : (
        <Markdown
          components={{
            code: ({ className, children }) => {
              const match = /language-(\w+)/.exec(className || "");
              return (
                <CodeBlock
                  language={match ? match[1] : null}
                  value={String(children).replace(/\n$/, "")}
                />
              );
            },
          }}
        ></Markdown>
      )}
    </div>
  );
};
