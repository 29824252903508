import React from "react";

interface HeaderProps {
  children?: React.ReactNode;
  content?: React.ReactNode;
}

export const Header = ({ children, content }: HeaderProps) => {
  return (
    <div className="flex flex-col border-b border-[#393939]">
      {content && (
        <div className="font-sans sticky top-0 bg-[#121212] border-[#393939] z-10">
          {content}
        </div>
      )}
      <div className="grow bg-[#121212]">{children}</div>
    </div>
  );
};
