import * as React from "react";
import useInterval from "../../utils/useInterval";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export interface LiveInferenceUsageProps {
  token: string;
}

interface LiveInferenceUsageData {
  usage: { timestamp: string; connection_count: number }[];
}

export default function LiveInferenceUsageChart(
  props: LiveInferenceUsageProps,
) {
  const [liveInferenceUsageInfo, setLiveInferenceUsageInfo] =
    React.useState<LiveInferenceUsageData>({ usage: [] });
  React.useEffect(() => {
    getLiveUserInferenceUsageFromBackend(props.token)
      .then((data) => {
        const new_arr = [...liveInferenceUsageInfo.usage];
        new_arr.push(data);
        if (new_arr.length > 60) {
          new_arr.shift();
        }
        setLiveInferenceUsageInfo({ usage: new_arr });
      })
      .catch(console.log);
  }, []);
  useInterval(() => {
    getLiveUserInferenceUsageFromBackend(props.token)
      .then((data) => {
        const new_arr = [...liveInferenceUsageInfo.usage];
        new_arr.push(data);
        if (new_arr.length > 60) {
          new_arr.shift();
        }
        setLiveInferenceUsageInfo({ usage: new_arr });
      })
      .catch(console.log);
  }, 1000);
  const data = React.useMemo(() => {
    return liveInferenceUsageInfo?.usage.map((elem) => {
      return { date: elem.timestamp, value: elem.connection_count };
    });
  }, [liveInferenceUsageInfo.usage]);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={data}
        margin={{ top: 20, right: 20, left: 5, bottom: 20 }}
      >
        <XAxis dataKey="date" />
        <YAxis
          label={{
            value: "Usage",
            angle: -90,
            position: "insideLeft",
          }}
        />
        <Tooltip />
        <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
        <Line type="monotone" dataKey="value" stroke="#8884d8" yAxisId={0} />
      </LineChart>
    </ResponsiveContainer>
  );
}

async function getLiveUserInferenceUsageFromBackend(token: string) {
  const res = await fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/live_metrics/inference",
    {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    },
  );
  const data = await res.json();
  return data;
}
