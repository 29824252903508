import Statsig from "statsig-js";

export const initializeStatsig = async (
  user_email: string,
  statsig_client_public_key: string,
  env: string,
  local_mode: boolean,
) => {
  await Statsig.initialize(statsig_client_public_key, { email: user_email });
};
