import * as React from "react";
import { TrainJob } from "../../utils/trainJob";
import checkIcon from "../../assets/check-icon.svg";
import copyIcon from "../../assets/copy-icon.svg";

interface TrainJobInformationProps {
  activeJob: TrainJob;
  copiedModel: boolean;
  setCopiedModel: React.Dispatch<React.SetStateAction<boolean>>;
  copiedDataset: boolean;
  setCopiedDataset: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TrainJobInformation({
  activeJob,
  copiedModel,
  setCopiedModel,
  copiedDataset,
  setCopiedDataset,
}: TrainJobInformationProps) {
  return (
    <div className="flex w-full justify-between text-[14px]">
      <div>
        <div className="flex w-full justify-start">
          <p>Model ID</p>
        </div>
        <div className="flex w-full justify-start truncate">
          {activeJob.model_name && (
            <div
              className="hover:bg-gray-700 flex"
              onClick={() => {
                navigator.clipboard.writeText(activeJob?.model_name);
                setCopiedModel(true);
              }}
            >
              {activeJob?.model_name}
              {copiedModel ? (
                <img
                  src={checkIcon}
                  alt="check-icon"
                  style={{
                    paddingLeft: "6px",
                  }}
                />
              ) : (
                <p>
                  <img
                    src={copyIcon}
                    alt="copy-icon"
                    style={{
                      paddingLeft: "6px",
                    }}
                  />
                </p>
              )}
            </div>
          )}
        </div>
        <br />
        <div className="flex w-full justify-start">
          <p>Dataset ID</p>
        </div>
        <div className="flex w-full justify-start truncate">
          {activeJob.dataset_id && (
            <div
              className="hover:bg-gray-700 flex"
              onClick={() => {
                navigator.clipboard.writeText(activeJob?.dataset_id);
                setCopiedDataset(true);
              }}
            >
              {activeJob?.dataset_id}
              {copiedDataset ? (
                <img
                  src={checkIcon}
                  alt="check-icon"
                  style={{
                    paddingLeft: "6px",
                  }}
                />
              ) : (
                <p>
                  <img
                    src={copyIcon}
                    alt="copy-icon"
                    style={{
                      paddingLeft: "6px",
                    }}
                  />
                </p>
              )}
            </div>
          )}
        </div>
        <br />
        {activeJob.status === "PARTIALLY COMPLETED"
          ? `This job was stopped due to a system timeout. Not all data may be trained on. The model checkpoint until then has been shared above.`
          : ``}
        {activeJob.status === "CANCELLED"
          ? `This job was paused by the user. The model checkpoint until then has been shared above.`
          : ``}
      </div>
    </div>
  );
}
