import React from "react";

import { AlignedSides } from "../layouts/AlignedSides";

interface TitleProps {
  value: string;
  right?: React.ReactNode;
  level?: number;
}

export const Title = ({ value, right, level }: TitleProps) => {
  const selectedLevel = level === undefined ? 1 : level;
  const fontSize =
    {
      0: "text-6xl",
      1: "text-4xl",
      2: "text-2xl",
      3: "text-xl",
      4: "text-lg",
    }[selectedLevel] || "text-base";
  return (
    <AlignedSides left={<h1 className={fontSize}>{value}</h1>} right={right} />
  );
};
