import * as React from "react";
import { useState, forwardRef } from "react";

interface FileInputProps {
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>;
}

const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  ({ setSelectedFile }, ref) => {
    const [error, setError] = useState("");

    const isJsonlFile = (file: File) => {
      return (
        file.name.endsWith(".jsonl") || file.type === "application/x-ndjson"
      );
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (!files) {
        setSelectedFile(null);
        setError("No file selected.");
        return;
      }
      const file = files[0];

      if (file && isJsonlFile(file)) {
        setSelectedFile(file);
        setError("");
      } else {
        setSelectedFile(null);
        setError("Please select a valid JSONL file.");
      }
    };

    return (
      <div className="py-4">
        <input
          type="file"
          onChange={handleFileChange}
          accept=".jsonl,application/x-ndjson"
          className="block w-full text-sm text-gray-500
            file:mr-4 file:py-2 file:px-4
            file:rounded-full file:border-0
            file:text-sm file:font-semibold"
          ref={ref}
        />
        {error && <p className="mt-2 text-red-500">{error}</p>}
      </div>
    );
  },
);

export default FileInput;
