import * as React from "react";

export const statusChip = (status: string) => {
  switch (status) {
    case "SCHEDULED":
      return (
        <span>
          <span style={{ color: "#3DCBFF" }}>&#9679;</span>
          &nbsp;Queued
        </span>
      );
    case "CREATED":
      return (
        <span>
          <span style={{ color: "#3DCBFF" }}>&#9679;</span>
          &nbsp;Queued
        </span>
      );
    case "QUEUED":
      return (
        <span>
          <span style={{ color: "#3DCBFF" }}>&#9679;</span>
          &nbsp;Queued
        </span>
      );
    case "LOADING DATA":
      return (
        <span>
          <span style={{ color: "#FFF351" }}>&#9679;</span>
          &nbsp;In Progress
        </span>
      );
    case "TRAINING MODEL":
      return (
        <span>
          <span style={{ color: "#FFF351" }}>&#9679;</span>
          &nbsp;In Progress
        </span>
      );
    case "EVALUATING MODEL":
      return (
        <span>
          <span style={{ color: "#FFF351" }}>&#9679;</span>
          &nbsp;In Progress
        </span>
      );
    case "SAVING MODEL":
      return (
        <span>
          <span style={{ color: "#FFF351" }}>&#9679;</span>
          &nbsp;In Progress
        </span>
      );
    case "COMPLETED":
      return (
        <span>
          <span style={{ color: "#1ADD82" }}>&#9679;</span>
          &nbsp;Completed
        </span>
      );
    case "PARTIALLY COMPLETED":
      return (
        <span>
          <span style={{ color: "#1ADD82" }}>&#9679;</span>
          &nbsp;TimedOut
        </span>
      );
    case "FAILED":
      return (
        <span>
          <span style={{ color: "#FF7863" }}>&#9679;</span>
          &nbsp;Failed
        </span>
      );
    case "CANCELLED":
      return (
        <span>
          <span style={{ color: "#ECECEC" }}>&#9679;</span>
          &nbsp;Paused
        </span>
      );
    default:
      return (
        <span>
          <span style={{ color: "#FFF351" }}>&#9679;</span>
          &nbsp;In Progress
        </span>
      );
  }
};
