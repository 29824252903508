import * as React from "react";

import logo from "../logos/logo-llama-only-white.png";

import "./StripeSuccess.css";

export default function StripeSuccess() {
  return (
    <div className="h-screen bg-[#0c0c0c]">
      <div className="flex items-center h-full">
        <div className="m-0 bg-[#313131]">
          <div className="p-8 text-[#f1f1f1] border-[#5f5f5f] border rounded-md">
            <img
              src={logo}
              className="w-20 m-0"
              // class="mascot-logo"
            />

            <h3 className="bold-text text-center mb-1 mt-4 text-lg font-bold">
              Successful Checkout!
            </h3>
            <div className="text-align-center margin-top items-center">
              <a
                className="mt-5 bg-grey-500 hover:bg-gray-700 text-white font-bold py-2 px-4 border border-grey-700 rounded"
                href="/"
              >
                Go Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
