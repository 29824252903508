import * as React from "react";

export interface UserContextType {
  profile: {
    token: null;
  } | null;
  setProfile: React.Dispatch<
    React.SetStateAction<{
      token: null;
    }>
  > | null;
}

const context = { profile: null, setProfile: null };
export const UserContext = React.createContext<UserContextType>(context);
