import * as React from "react";
import "./index.css";
import ReactDOM from "react-dom/client";
import GlobalDataProvider from "./GlobalDataProvider";
import App from "./App";

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");

const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <GlobalDataProvider>
      <App />
    </GlobalDataProvider>
  </React.StrictMode>,
);
