export default function setPublic(model_name: string, token: string) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  fetch(
    (process.env.REACT_APP_API_URL || "") +
      "/v1/model/" +
      encodeURIComponent(model_name) +
      "/public",
    {
      method: "POST",
      headers: myHeaders,
    },
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .catch((error) => {
      console.error("Error making model public: ", error);
    });
}
