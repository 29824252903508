import React from "react";
import ModelSelector, { ModelInfo } from "./ModelSelector";

interface PlaygroundSettingsProps {
  models: ModelInfo[];
  selectedModel: ModelInfo;
  setSelectedModel: (model: ModelInfo) => void;
  outputLength: number;
  setOutputLength: React.Dispatch<React.SetStateAction<number>>;
}

/*
 * Primary UI component for user interaction
 */
export default function PlaygroundSettings({
  models,
  selectedModel,
  setSelectedModel,
  outputLength,
  setOutputLength,
}: PlaygroundSettingsProps) {
  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);
    setOutputLength(newValue);
  };

  return (
    <div>
      <div
        className="mb-4 hidden md:block bg-[#121212] border border-[#393939] rounded-[16px] p-4"
        style={{
          alignItems: "center",
        }}
      >
        <h1 className="mb-2">Model</h1>
        <ModelSelector
          models={models}
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
        />
      </div>

      <div
        className="mb-4 bg-[#121212] border border-[#393939] rounded-[16px] p-4"
        style={{
          alignItems: "center",
        }}
      >
        <h1 className="mb-2">Output Length</h1>
        <input
          type="range"
          min="0"
          max={selectedModel.maxLength}
          step="1"
          value={outputLength}
          onChange={handleSliderChange}
          style={{
            height: 4,
            borderRadius: 4,
            outline: "none",
            WebkitAppearance: "none",
            MozAppearance: "none",
            appearance: "none",
            backgroundColor: "#9E9E9E",
          }}
          className="w-full"
        />

        <label className="text-center" htmlFor="outputLength">
          {outputLength}
        </label>
      </div>
      {selectedModel.name !== "Phi 3 4K Instruct" && (
        <div
          className="mb-4 bg-[#121212] border border-[#393939] rounded-[16px] p-4"
          style={{
            alignItems: "center",
          }}
        >
          <h1 className="mb-2">System Prompt</h1>
          <textarea
            className="w-full break-words"
            value={selectedModel.systemPrompt}
            onChange={(e) =>
              setSelectedModel({
                ...selectedModel,
                systemPrompt: e.target.value,
              })
            }
            rows={Math.max(
              1,
              Math.ceil(selectedModel.systemPrompt.length / 40),
            )}
            style={{
              border: "none",
              borderRadius: 12,
              WebkitAppearance: "none",
              MozAppearance: "none",
              appearance: "none",
              fontSize: 14,
              backgroundColor: "#1d1d1d",
              width: "100%",
              maxHeight: "540px",
              resize: "none",
            }}
          />
        </div>
      )}
    </div>
  );
}
