export default function fetchTrainingLog(
  job_id: string,
  setTrainingLog: (trainingLog: Array<string>) => void,
  token: string,
) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  fetch(
    (process.env.REACT_APP_API_URL || "") + "/v1/train/training_log/" + job_id,
    {
      method: "POST",
      headers: myHeaders,
    },
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .then((json) => {
      setTrainingLog(json);
    })
    .catch((error) => {
      setTrainingLog([
        "Error fetching training log, see console for more details",
      ]);
    });
}
