export default function fetchModel(
  model_name: string | null,
  setIsPub: (isPub: boolean) => void,
  setCustomModelName: (customModelName: string) => void,
  token: string,
) {
  if (!model_name) {
    return;
  }
  fetch(
    (process.env.REACT_APP_API_URL || "") +
      "/v1/model/" +
      encodeURIComponent(model_name),
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .then((json) => {
      // Just returning what we use right now... can be changed later
      setIsPub(json.isPublic == "true");
      setCustomModelName(json.customModelName);
    })
    .catch((error) => {
      console.error("Error getting model: ", error);
    });
}
