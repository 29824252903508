// src/contexts/GlobalDataContext.js
import React, { createContext } from "react";

export interface GlobalDataContextType {
  config: { [key: string]: any };
  loading: boolean;
}

export const GlobalDataContext = createContext<GlobalDataContextType>({
  config: {},
  loading: true,
});
